<script setup lang="ts">
const { $storageManagerFav } = useNuxtApp()
const storeContratti = useStoreCcnlContract()
const listFavorite = ref<number[]>([])

onMounted(() => {
	const list = $storageManagerFav.query.list() // $storageManagerFav.get('favoriteCCNL') as number[]
	if (list) {
		listFavorite.value = list
	}
})

const GetCCNL = (id: number) => {
	const ccnl = storeContratti.getCcnlContractId(id)
	if (ccnl) {
		return ccnl// .nome_sub_settore
	}
	return undefined
}
</script>

<template>
  <h6><Icon name="line-md:star-filled" size="25" class="text-yellow" />Preferiti </h6>
  <div class="flex flex-col">
    <!-- <div class="flex align-content-center  align-items-center">
      <div class="mr-4" />
      <div class="">
        <Button label="Preferiti" class="p-button-outlined" />
      </div>
    </div> -->
    <ul class="list-none p-0 m-0">
      <li v-for="(item, index) in listFavorite" :key="index" class="text-xl py-2 border-bottom-1 surface-border">
        <NuxtLink :to="`/ccnl/contratto-${GetCCNL(item)?.id_testata}_`">
          <div class="cursor-pointer flex align-items-center justify-content-between hover:surface-200 transition-colors transition-duration-150 border-round p-2 p-ripple">
            <div
              class="mr-3 flex-shrink-0 border-round bg-primary text-base font-semibold flex align-items-center justify-content-center  h-15"
            >
              <div class=" white-space-normal m-2">
                {{ `${GetCCNL(item)?.codice_CNEL} ${GetCCNL(item)?.codice_sub_settore}` }}
              </div>
            </div>

            <div>
              <!-- <span class="block text-3 mb-1">INPS: {{ GetCCNL(item)?.codice_INPS }}</span> -->

              <p class="m-0 text-color-900 font-bold text-base">
                {{ GetCCNL(item)?.descrizione_CCNL }}
              </p><div class=" pt-1 text-xs line-height-1">
                {{ GetCCNL(item)?.nome_sub_settore }}
              </div>
            </div>
            <i class="ml-auto pi pi-arrow-right text-600 text-primary-700 text-sm" />
          </div>
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>
